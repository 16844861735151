<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Meetings</h3>

			<div class="d-inline-flex align-center">
				<div v-if="search['show']" class="d-inline-block mr-3">
					<v-text-field class="fsk-v-input" v-model="search['input']" name="search" type="search" dense hide-details="auto" prepend-inner-icon="mdi-magnify" outlined clearable></v-text-field>
				</div>
				<v-btn v-if="!search['show']" icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="search['show'] = true"><v-icon>mdi-magnify</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click.stop="showForm"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="resetTable()"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-card :loading="loading" v-if="!reset">
				<v-card-text>
					<v-data-table
						class="fsk-v-data-table"
						:headers="headers"
						:items="table"
						:search="search['input']"
					>
						<template v-slot:[`item.name`]="{ item }">
							<div class="py-3">
								<div>{{ item['name'] }}</div>
								<div class="caption">{{ item['company'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.phone`]="{ item }">
							<div>
								<div>{{ item['phone'] }}</div>
								<div class="caption">{{ item['email'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.agenda`]="{ item }">
							<div class="v-data-table-text-truncate">{{ item['agenda'] }}</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>
								<div>{{ $moment.utc(item['date']).format('DD MMM YYYY') }}</div>
								<div class="caption">{{ formatTime(item['time']) }}</div>
							</div>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div class="text-center">
								<v-chip v-if="item['status']" :color="getStatusColor(item.status)" x-small outlined>{{ item['status'] }}</v-chip>
							</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<div class="v-data-table-actions">
								<v-menu bottom left>
									<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
									</template>

									<v-list>
									<v-list-item dense @click="editItem(item)">
										<v-list-item-title>View / Edit</v-list-item-title>
									</v-list-item>
									<v-list-item dense @click="updateStatus(item)">
										<v-list-item-title>Update Status</v-list-item-title>
									</v-list-item>
									<v-list-item dense @click="deleteItem(item)">
										<v-list-item-title>Delete</v-list-item-title>
									</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</div>

		<!-- Create Dialog -->
		<v-dialog
			v-model="form['show']"
			persistent
			scrollable
			content-class="fsk-v-create-dialog"
		>
			<v-card :loading="form['loading']" :disabled="form['loading']">
				<v-card-title>
					<span v-if="form['create']" class="headline">New Meeting</span>
					<span v-else class="headline">Edit Meeting</span>
				</v-card-title>
				<v-card-text style="max-height: 80vh;">
					<v-row>
						<v-col cols="12" md="6">
							<v-row>
								<v-col cols="6" class="py-0">
									<v-dialog ref="dialogDate" v-model="modal['date']" :return-value.sync="form['data']['date']" persistent width="290px">
										<template v-slot:activator="{ on, attrs }">
										<v-text-field :value="$moment.utc(form['data']['date']).format('DD MMM YYYY')" class="fsk-v-input" label="Date *" name="date" readonly outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-date-picker v-model="form['data']['date']" scrollable @input="$refs.dialogDate.save(form['data']['date'])">
										</v-date-picker>
									</v-dialog>
								</v-col>
								<v-col cols="6" class="py-0">
									<v-dialog ref="dialogTime" v-model="modal['time']" :return-value.sync="form['data']['time']" persistent width="290px">
										<template v-slot:activator="{ on, attrs }">
										<v-text-field :value="formatTime(form['data']['time'])" class="fsk-v-input" label="Time *" name="time" readonly outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-time-picker v-if="modal['time']" v-model="form['data']['time']" full-width  @click:minute="$refs.dialogTime.save(form['data']['time'])">
										</v-time-picker>
									</v-dialog>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="6"></v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['contact']" class="fsk-v-input" :items="['Charles Cooper - Hangar Pvt. Ltd', 'Stephen Clark - Smart Ltd', 'Russell Lee - Rus Corp', 'Rachel Wood - CC Corp.', 'Joel Hill - WebTech', 'Kevin Harris - Shazar Tech']" label="Contact *" name="contact" outlined dense hide-details="auto"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['assigned']" class="fsk-v-input" :items="['Rachel Castro', 'Austin Hicks', 'Bryan Mitchell', 'Nancy Lewis', 'Mark Bradley', 'Jack Bates', 'Sandra Payne']" label="Assigned *" name="assigned" outlined dense hide-details="auto"></v-select>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="form['data']['agenda']" class="fsk-v-input" label="Agenda *" name="agenda" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="form['data']['address']" class="fsk-v-input" label="Address *" name="address" rows="2" outlined dense hide-details="auto" no-resize></v-textarea>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="form['data']['description']" class="fsk-v-input" label="Description *" name="description" rows="2" outlined dense hide-details="auto" no-resize></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4 px-6">
					<v-spacer></v-spacer>
					<v-btn color="grey" text @click="hideForm()">Close</v-btn>
					<v-btn color="primary" text @click="submitForm()">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Status Dialog -->
		<v-dialog
			v-model="tag['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="tag['loading']" :disabled="tag['loading']">
			<v-card-title class="headline">Update Status</v-card-title>

			<v-card-text style="max-height: 80vh;">
				<v-row>
					<v-col cols="12">
						<v-select v-model="tag['status']" class="fsk-v-input" :items="['Pending', 'Done', 'Cancelled']" label="Status *" name="status" outlined dense :color="getStatusColor(tag['status'])" hide-details="auto"></v-select>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideStatus()">Close</v-btn>
				<v-btn color="primary" text @click="confirmStatus()">Save</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<!-- Delete Dialog -->
		<v-dialog
			v-model="del['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="del['loading']" :disabled="del['loading']">
			<v-card-title class="headline">Delete Meeting</v-card-title>

			<v-card-text>Please confirm the deletion of meeting with <strong>{{ del['name'] }}</strong></v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideDelete()">Close</v-btn>
				<v-btn color="primary" text @click="confirmDelete()">Confirm</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data: () => ({
			modal: {
				date: false,
				time: false
			},
			search: {
				show: false,
				input: ''
			},
			loading: false,
			reset: false,
			headers: [
				{
					text: 'Name | Company',
					align: 'start',
					value: 'name',
				},
				{
					text: 'Contact',
					value: 'phone',
				},
				{
					text: 'Agenda',
					value: 'agenda',
				},
				{
					text: 'Date',
					value: 'date',
				},
				{
					text: 'Assigned',
					value: 'assigned',
				},
				{
					text: 'Status',
					value: 'status',
					align: 'center',
				},
				{ text: 'Actions', align: 'end', value: 'actions', sortable: false },
			],
			table: [],
			form: {
				create: true,
				show: false,
				loading: false,
				data: {
					id: '',
					date: '',
					time: '',
					agenda: '',
					address: '',
					name: '',
					contact: '',
					assigned: '',
					status: '',
					description: '',
				}
			},
			tag: {
				show: false,
				loading: false,
				id: '',
				status: ''
			},
			del: {
				show: false,
				loading: false,
				id: '',
				name: ''
			}
		}),

		created() {
			this.initialize();
		},

		methods: {
			initialize () {
				this.table = [
					{
						id: 1,
						name: 'Kevin Harris',
						company: 'Shazar Tech',
						phone: '9231457895',
						email: 'kevin@shazar.com',
						agenda: 'Followup',
						address: 'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
						assigned: 'Austin Hicks',
						status: 'Pending',
						date: new Date(),
						time: '18:00'
					},
					{
						id: 2,
						name: 'Rachel Wood',
						company: 'CC Corp.',
						phone: '9212678992',
						email: 'rachel@ccc.com',
						agenda: 'Product Showcase',
						address: 'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
						assigned: 'Rachel Castro',
						status: 'Done',
						date: new Date(Date.now() - 6480000000),
						time: '13:00'
					},
					{
						id: 3,
						name: 'Russell Lee',
						company: 'Rus Corp',
						phone: '9231451423',
						email: 'russell@rcorp.com',
						agenda: 'Quotation Approval',
						address: 'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
						assigned: 'Jack Bates',
						status: 'Done',
						date: new Date(Date.now() - 3888000000),
						time: '09:30'
					},
					{
						id: 4,
						name: 'Stephen Clark',
						company: 'Smart Ltd',
						phone: '9231457895',
						email: 'stephen@smart.com',
						agenda: 'Documents Collection',
						address: 'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
						status: 'Pending',
						assigned: 'Sandra Payne',
						date: new Date(Date.now() + 86400000),
						time: '10:30'
					},
					{
						id: 5,
						name: 'Charles Cooper',
						company: 'Hangar Pvt. Ltd',
						phone: '9231457895',
						email: 'charles@hangar.com',
						agenda: 'Followup',
						address: 'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
						assigned: 'Austin Hicks',
						status: 'Cancelled',
						date: new Date(Date.now() - 7344000000),
						time: '17:00'
					},
				]
			},
			resetTable() {
				this.loading = true;

				setTimeout(() => {
					this.loading = false;
					this.reset = true;
					this.reset = false;
				}, 3000);
			},
			getStatusColor (status) {
				switch (status) {					
					case 'Pending':
						return 'orange'
					
					case 'Done':
						return 'success'
					
					case 'Cancelled':
						return 'red'
			
					default:
						return 'grey'
				}
			},
			editItem(item) {
				// Assign form data
				this.form['data']['id'] = item['id'];
				this.form['data']['date'] = item['date'];
				this.form['data']['time'] = item['time'];
				this.form['data']['agenda'] = item['agenda'];
				this.form['data']['address'] = item['address'];
				this.form['data']['name'] = item['name'];
				this.form['data']['contact'] = `${item['name']} - ${item['company']}`;
				this.form['data']['assigned'] = item['assigned'];
				this.form['data']['status'] = item['status'];
				this.form['data']['description'] = item['description'];

				// Show dialog
				this.showForm('edit');
			},
			updateStatus(item) {
				// Assign data
				this.tag['id'] = item['id'];
				this.tag['status'] = item['status'];

				// Show dialog
				this.tag['show'] = true;
			},
			hideStatus() {
				// Reset data
				this.tag['id'] = '';
				this.tag['status'] = '';

				// Unset loading
				this.tag['loading'] = false;

				// Hide dialog
				this.tag['show'] = false;
			},
			confirmStatus() {
				// TODO: Add update status API
				console.log(this.tag['id']);

				// Set loading
				this.tag['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideStatus();
				}, 3000);
			},
			deleteItem(item) {
				// Assign data
				this.del['id'] = item['id'];
				this.del['name'] = item['name'];

				// Show dialog
				this.del['show'] = true;
			},
			hideDelete() {
				// Reset data
				this.del['id'] = '';
				this.del['name'] = '';

				// Unset loading
				this.del['loading'] = false;

				// Hide dialog
				this.del['show'] = false;
			},
			confirmDelete() {
				// TODO: Add delete meeting API
				console.log(this.del['id']);

				// Set loading
				this.del['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideDelete();
				}, 3000);
			},
			resetForm() {
				this.form['data']['id'] = '';
				this.form['data']['date'] = this.$moment.utc(new Date()).format('YYYY-MM-DD');
				this.form['data']['time'] = '';
				this.form['data']['agenda'] = '';
				this.form['data']['address'] = '';
				this.form['data']['name'] = '';
				this.form['data']['contact'] = '';
				this.form['data']['assigned'] = '';
				this.form['data']['status'] = '';
				this.form['data']['description'] = '';
			},
			showForm(type = 'create') {
				if (type == 'edit') {
					this.form['create'] = false;
				}
				else {
					this.form['create'] = true;

					// Prepopulate data
					this.form['data']['date'] = this.$moment.utc(new Date()).format('YYYY-MM-DD');
				}

				// Show Dialog
				this.form['show'] = true;
			},
			hideForm() {
				// Hide dialog
				this.form['show'] = false;

				// Unset loading
				this.form['loading'] = false;

				// Clear form
				this.resetForm();
			},
			submitForm() {
				if (this.form['create']) {
					// TODO: Add create meeting API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				else {
					// TODO: Add edit meeting API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				setTimeout(() => {
					// Hide dialog
					this.hideForm();
				}, 3000);
			}
		}
	}
</script>
